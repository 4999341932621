<template>
  <div>
    <v-simple-table dense>
      <template v-slot:default>
        <tbody>
          <tr class="v-row-group__header">
            <th :colspan="2">Zur Person</th>
          </tr>
          <tr v-if="user.salutation || user.letterSalutation">
            <td class="font-weight-medium">Anrede/Briefanrede</td>
            <td>
              {{ user.salutation
              }}<span v-if="user.salutation && user.letterSalutation">/</span
              >{{ user.letterSalutation }}
            </td>
          </tr>
          <tr v-if="user.title">
            <td class="font-weight-medium">Titel</td>
            <td>{{ user.title }}</td>
          </tr>
          <tr>
            <td class="font-weight-medium">Vorname</td>
            <td>{{ user.firstName }}</td>
          </tr>
          <tr>
            <td class="font-weight-medium">Nachname</td>
            <td>{{ user.lastName }}</td>
          </tr>

          <tr v-if="user.birthdate">
            <td class="font-weight-medium">Geburtsdatum (Alter)</td>
            <td>
              {{ timestampConverter(user.birthdate) }} ({{
                getAge(user.birthdate.toDate())
              }})
            </td>
          </tr>
          <tr v-if="user.birthplace">
            <td class="font-weight-medium">Geburtsort</td>
            <td>{{ user.birthplace }}</td>
          </tr>
          <tr class="v-row-group__header">
            <th :colspan="2">Zur Mitgliedschaft</th>
          </tr>
          <tr>
            <td class="font-weight-medium">Personalnummer</td>
            <td>
              <pre>{{ user.personnelNumber }}</pre>
            </td>
          </tr>
          <tr v-if="user.dutyPosition.title">
            <td class="font-weight-medium">Dienststellung</td>
            <td>
              <img
                v-if="user.dutyPosition.badgeUrl.size125x125"
                contain
                :src="user.dutyPosition.badgeUrl.size125x125"
                height="25px"
                class="mr-2 my-2 d-block"
              />
              {{ user.dutyPosition.title
              }}<span v-if="user.dutyPosition.since"
                ><br />(seit
                {{ timestampConverter(user.dutyPosition.since) }})</span
              >
            </td>
          </tr>
          <tr v-if="user.rank.title">
            <td class="font-weight-medium">Dienstgrad</td>
            <td>
              <img
                v-if="user.rank.badgeUrl.size125x125"
                contain
                :src="user.rank.badgeUrl.size125x125"
                height="25px"
                class="mr-2 my-2 d-block"
              />

              {{ user.rank.title
              }}<span v-if="user.rank.since"
                ><br />(seit {{ timestampConverter(user.rank.since) }})</span
              >
            </td>
          </tr>
          <tr v-if="user.division.title">
            <td class="font-weight-medium">Einheit/Abteilung</td>
            <td>
              {{ user.division.title }}
            </td>
          </tr>
          <tr v-if="user.memberSince">
            <td class="font-weight-medium">Mitglied seit</td>
            <td>{{ timestampConverter(user.memberSince) }}</td>
          </tr>
          <tr v-if="user.memberUntil">
            <td class="font-weight-medium">Mitglied bis</td>
            <td>{{ timestampConverter(user.memberUntil) }}</td>
          </tr>
          <tr v-if="user.note" class="v-row-group__header">
            <th :colspan="2">Sonstiges</th>
          </tr>
          <tr v-if="user.note">
            <td class="font-weight-medium">Notizen</td>
            <td>{{ user.note }}</td>
          </tr>
          <tr>
            <td :colspan="2">
              <div class="font-weight-medium my-1">Aufgabenbereiche</div>
              <div v-if="!user.assignments?.ids?.length" class="mb-2">
                Keine Aufgabenbereiche zugewiesen
              </div>
              <v-chip
                v-else
                v-for="(assignment, index) in user.assignments?.data"
                :key="index"
                small
                label
                :color="`${getMaterialColor(assignment.color)} lighten-5`"
                class="font-weight-medium text--darken-4 mr-2 mb-2"
                :class="getTextStyle(assignment.color)"
                ><v-icon small class="mr-2" :color="assignment.color"
                  >mdi-{{ assignment.icon }}</v-icon
                >{{ assignment.title }}</v-chip
              >
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  name: "personnel-record-general-table",
  components: {},
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getAge(birthdate) {
      return new Date().getFullYear() - new Date(birthdate).getFullYear();
    },
    timestampConverter(timestamp) {
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      };

      return timestamp.toDate().toLocaleDateString("de-DE", options);
    },
  },
};
</script>
