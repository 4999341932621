<template>
  <div>
    <section>
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <div class="mt-7">
              <v-card>
                <personnel-record-skills-data-table
                  v-model="helpers.skillSelection"
                  :skills="skills"
                  mode="manage"
                  @editSkill="editSkill"
                  @deleteSkill="deleteSkill"
                >
                  <template v-slot:top>
                    <v-card flat>
                      <v-card-title class="body-2 v-card__subtitle"
                        ><v-icon left small class="mr-2">mdi-label</v-icon
                        >Qualifikationen
                        <v-spacer></v-spacer>
                        <v-btn
                          depressed
                          :disabled="!helpers.skillSelection.length"
                          color="error"
                          class="mr-2"
                          @click="deleteSkillSelection()"
                          ><v-icon left>mdi-delete</v-icon>Auswahl löschen
                        </v-btn>
                        <v-btn
                          depressed
                          color="success"
                          @click="toggleSkillFormDialog()"
                          ><v-icon left>mdi-plus</v-icon>Hinzufügen
                        </v-btn>
                      </v-card-title>
                    </v-card>
                  </template>
                </personnel-record-skills-data-table>
              </v-card>
            </div>
          </v-col>
          <v-col cols="12">
            <div class="mt-7">
              <v-card>
                <personnel-record-courses-data-table
                  v-model="helpers.courseSelection"
                  :courses="courses"
                  mode="manage"
                  @editCourse="editCourse"
                  @deleteCourse="deleteCourse"
                >
                  <template v-slot:top>
                    <v-card flat>
                      <v-card-title class="body-2 v-card__subtitle"
                        ><v-icon left small class="mr-2"
                          >mdi-book-education</v-icon
                        >Lehrgänge
                        <v-spacer></v-spacer>
                        <v-btn
                          depressed
                          :disabled="!helpers.courseSelection.length"
                          color="error"
                          class="mr-2"
                          @click="deleteCourseSelection()"
                          ><v-icon left>mdi-delete</v-icon>Auswahl löschen
                        </v-btn>
                        <v-btn
                          depressed
                          color="success"
                          @click="toggleCourseFormDialog()"
                          ><v-icon left>mdi-plus</v-icon>Hinzufügen
                        </v-btn>
                      </v-card-title>
                    </v-card>
                  </template>
                </personnel-record-courses-data-table>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <custom-dialog
        v-if="helpers.courseForm.dialogModel"
        @close="toggleCourseFormDialog()"
        :title="helpers.courseForm.title"
        width="m"
        :btnAction="courseAction"
        :btnText="helpers.courseForm.btnText"
        :loading="helpers.courseForm.isLoading"
        :errorMessage="helpers.courseForm.errorMessage"
        :btnDisabled="!helpers.courseForm.formIsValid"
        btnColor="success"
      >
        <template v-slot:content>
          <v-form
            v-model="helpers.courseForm.formIsValid"
            @submit.prevent="courseAction()"
            class="mt-2"
          >
            <v-row>
              <v-col cols="12">
                <v-card outlined>
                  <v-card-subtitle
                    ><v-icon left small class="mr-2">mdi-card-text</v-icon
                    >Lehrgangsdaten</v-card-subtitle
                  >
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-select
                          v-model="helpers.courseForm.input.course"
                          label="Lehrgang"
                          :items="availableCourses"
                          item-text="title"
                          return-object
                          :rules="[rules.required]"
                          hide-details="auto"
                          outlined
                        ></v-select>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        v-if="helpers.courseForm.input.course"
                        ><v-text-field
                          v-model="helpers.courseForm.input.course.group.title"
                          label="Lehrgangsgruppe"
                          read-only
                          disabled
                          hide-details="auto"
                          outlined
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="4">
                        <v-text-field
                          v-model="helpers.courseForm.input.duration.startDate"
                          label="Beginn"
                          :rules="[rules.required]"
                          hide-details="auto"
                          type="date"
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <v-text-field
                          v-model="helpers.courseForm.input.duration.endDate"
                          label="Abschluss"
                          :rules="[rules.required]"
                          type="date"
                          hide-details="auto"
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <v-text-field
                          v-model="helpers.courseForm.input.duration.proofDate"
                          label="Nachweisdatum"
                          :rules="[rules.required]"
                          type="date"
                          hide-details="auto"
                          outlined
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="6">
                        <v-select
                          v-model="helpers.courseForm.input.rating"
                          label="Bewertung"
                          :items="coursesRatingOptions"
                          :rules="[rules.required]"
                          type="date"
                          hide-details="auto"
                          outlined
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model.trim="helpers.courseForm.input.externalName"
                          label="Externe Bezeichnung"
                          hint="z.B. Kursbezeichnung, Lehrgangsbezeichnung"
                          hide-details="auto"
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model.trim="helpers.courseForm.input.externalId"
                          label="Externe Nummer"
                          hint="z.B. Kursnummer, Lehrgangskennung"
                          hide-details="auto"
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-textarea
                          v-model="helpers.courseForm.input.note"
                          label="Bemerkung"
                          hide-details="auto"
                          outlined
                          auto-grow
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-form>
        </template>
      </custom-dialog>
      <custom-dialog
        v-if="helpers.skillForm.dialogModel"
        @close="toggleSkillFormDialog()"
        :title="helpers.skillForm.title"
        width="m"
        :btnAction="skillAction"
        :btnText="helpers.skillForm.btnText"
        :loading="helpers.skillForm.isLoading"
        :errorMessage="helpers.skillForm.errorMessage"
        :btnDisabled="!helpers.skillForm.formIsValid"
        btnColor="success"
      >
        <template v-slot:content>
          <v-form
            v-model="helpers.skillForm.formIsValid"
            @submit.prevent="skillAction()"
            class="mt-2"
          >
            <v-row>
              <v-col cols="12">
                <v-card outlined>
                  <v-card-subtitle
                    ><v-icon left small class="mr-2">mdi-card-text</v-icon
                    >Allgemein</v-card-subtitle
                  >
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-autocomplete
                          v-model="helpers.skillForm.input.skill"
                          label="Qualifikation"
                          item-text="title"
                          return-object
                          :rules="[rules.required]"
                          :items="availableSkills"
                          hide-details="auto"
                          outlined
                        >
                          <template v-slot:selection="{ item }">
                            <img
                              contain
                              height="30px"
                              :src="item.badgeUrl.size200x200"
                              class="mr-2 py-1"
                            />
                            <div class="font-weight-medium">
                              {{ item.title }}
                            </div>
                          </template>
                          <template v-slot:item="{ item }">
                            <template v-if="typeof item !== 'object'">
                              <v-list-item-content>{{
                                item
                              }}</v-list-item-content>
                            </template>
                            <template v-else>
                              <v-list-item-avatar tile>
                                <v-img
                                  contain
                                  :src="item.badgeUrl.size200x200"
                                />
                              </v-list-item-avatar>

                              <v-list-item-content>
                                <v-list-item-title class="font-weight-medium">{{
                                  item.title
                                }}</v-list-item-title>
                                <v-list-item-subtitle>{{
                                  item.shortTitle
                                }}</v-list-item-subtitle>
                              </v-list-item-content>
                            </template></template
                          >
                        </v-autocomplete>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        v-if="helpers.skillForm.input.skill"
                        ><v-text-field
                          v-model="helpers.skillForm.input.skill.group.title"
                          label="Qualifikationsgruppe"
                          read-only
                          disabled
                          hide-details="auto"
                          outlined
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="4">
                        <v-text-field
                          v-model="helpers.skillForm.input.qualification.since"
                          label="Qualifikation seit"
                          hide-details="auto"
                          type="date"
                          disabled
                          clearable
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <v-text-field
                          v-model="helpers.skillForm.input.qualification.until"
                          label="Qualifikation bis"
                          clearable
                          disabled
                          type="date"
                          hide-details="auto"
                          outlined
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12">
                        <v-textarea
                          v-model.trim="helpers.skillForm.input.note"
                          label="Bemerkung"
                          hide-details="auto"
                          outlined
                          auto-grow
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-form>
        </template>
      </custom-dialog>
      <v-snackbar
        v-model="helpers.snackbar.model"
        class="pb-4"
        bottom
        app
        timeout="2000"
        ><v-icon
          :color="helpers.snackbar.type === 'success' ? 'success' : 'error'"
          class="mr-1"
          >{{
            helpers.snackbar.type === "success"
              ? "mdi-check-circle"
              : "mdi-error"
          }}</v-icon
        >
        {{ helpers.snackbar.message }}
      </v-snackbar>
    </section>
  </div>
</template>

<script>
import { db, FieldValue, Timestamp, auth } from "@/firebase";
import { handleFirestoreError } from "@/util/firebase/FirebaseErrorHandling.js";
import CustomDialog from "@/components/_systemwide/CustomDialog.vue";
import PersonnelRecordCoursesDataTable from "@/components/member-management/personnel-records/PersonnelRecordCoursesDataTable.vue";
import PersonnelRecordSkillsDataTable from "@/components/member-management/personnel-records/PersonnelRecordSkillsDataTable.vue";
import { MEMBER_MANAGEMENT } from "@/store/modules.js";
import { GET_COURSES, GET_SKILLS } from "@/store/action-types.js";
export default {
  name: "member-management-personnel-records-edit-certificates",
  components: {
    CustomDialog,
    PersonnelRecordCoursesDataTable,
    PersonnelRecordSkillsDataTable,
  },
  data() {
    return {
      courses: [],
      skills: [],

      coursesRatingOptions: [
        { text: "Bestanden", value: "passed" },
        { text: "Nicht bestanden", value: "failed" },
        { text: "Abgebrochen", value: "aborted" },
        { text: "Nicht bewertet", value: "unrated" },
      ],

      helpers: {
        snackbar: {
          model: false,
          message: "",
        },
        courseSelection: [],
        skillSelection: [],
        courseForm: {
          title: "Lehrgang hinzufügen",
          btnText: "Hinzufügen",
          dialogModel: false,
          formIsValid: false,
          isLoading: false,
          errorMessage: "",
          input: {
            course: null,
            externalId: null,
            externalName: null,
            duration: {
              startDate: null,
              endDate: null,
              proofDate: null,
            },
            rating: null,
            note: null,
          },
        },
        skillForm: {
          title: "Qualifikation hinzufügen",
          btnText: "Hinzufügen",
          dialogModel: false,
          formIsValid: false,
          isLoading: false,
          errorMessage: "",
          input: {
            skill: null,
            qualification: {
              since: null,
              until: null,
            },
            note: null,
          },
        },
      },
      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
      },
    };
  },

  computed: {
    availableCourses() {
      return this.$store.state.memberManagement.courses.map((item) => {
        return {
          title: item.title,
          group: item.group,
          type: item.type,
          id: item.meta.id,
        };
      });
    },
    availableSkills() {
      const groupItems = (arr) => {
        return arr.reduce((a, c) => {
          if (typeof a[c.group.title] === "undefined") a[c.group.title] = [];
          a[c.group.title] = [...a[c.group.title], c].sort(
            (a, b) => a.sortKey - b.sortKey
          );
          return a;
        }, {});
      };
      const insertHeaders = (obj) => {
        const sortedEntries = Object.entries(obj).sort(
          (a, b) => a[1][0].group.sortKey - b[1][0].group.sortKey
        );
        return sortedEntries.reduce((a, [key, vals], i, d) => {
          let r = [
            {
              header: key,
            },
            ...vals,
          ];
          if (i < d.length - 1) {
            r = [
              ...r,
              {
                divider: true,
              },
            ];
          }
          a = [...a, ...r];
          return a;
        }, []);
      };

      const skills = this.$store.state.memberManagement.skills.map((item) => {
        return {
          title: item.title,
          shortTitle: item.shortTitle,
          group: item.group,
          sortKey: item.sortKey,
          badgeUrl: item.badgeUrl,
          id: item.meta.id,
        };
      });

      const groupedSkills = groupItems(skills);
      return insertHeaders(groupedSkills);
    },
  },
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    initialize() {
      this.$store.dispatch(`${MEMBER_MANAGEMENT}${GET_COURSES}`, {
        organizationId: this.$route.params.organizationId,
      });
      this.$store.dispatch(`${MEMBER_MANAGEMENT}${GET_SKILLS}`, {
        organizationId: this.$route.params.organizationId,
      });
      this.getCourses();
      this.getSkills();
    },
    convertToTimestamp(date, time) {
      const input = date + " " + time;
      return Timestamp.fromDate(new Date(input.replace(/-/g, "/")));
    },
    convertTimestampToDate(timestamp) {
      return timestamp
        .toDate()
        .toLocaleDateString("de-DE", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
        .split(".")
        .reverse()
        .join("-");
    },
    getCourses() {
      return db
        .collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("personnelRecords_courses")
        .where("userId", "==", this.$route.params.itemId)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.courses.push(doc.data());
          });
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
        });
    },
    getSkills() {
      return db
        .collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("personnelRecords_skills")
        .where("userId", "==", this.$route.params.itemId)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.skills.push(doc.data());
          });
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
        });
    },
    toggleCourseFormDialog() {
      this.helpers.courseForm.title = "Lehrgang hinzufügen";
      this.helpers.courseForm.btnText = "Hinzufügen";
      this.helpers.courseForm.errorMessage = "";
      this.helpers.courseForm.input = {
        id: 0,
        course: null,
        duration: {
          startDate: null,
          endDate: null,
          proofDate: null,
        },
        rating: null,
        externalName: null,
        externalId: null,
        note: null,
      };
      this.helpers.courseForm.dialogModel =
        !this.helpers.courseForm.dialogModel;
    },
    toggleSkillFormDialog() {
      this.helpers.skillForm.title = "Qualifikation hinzufügen";
      this.helpers.skillForm.btnText = "Hinzufügen";
      this.helpers.skillForm.errorMessage = "";
      this.helpers.skillForm.input = {
        id: 0,
        skill: null,
        qualification: {
          since: null,
          until: null,
        },
        note: null,
      };
      this.helpers.skillForm.dialogModel = !this.helpers.skillForm.dialogModel;
    },

    courseAction() {
      this.helpers.courseForm.isLoading = true;
      if (this.helpers.courseForm.title === "Lehrgang hinzufügen") {
        this.addCourse();
      } else {
        this.updateCourse();
      }
    },
    skillAction() {
      this.helpers.skillForm.isLoading = true;
      if (this.helpers.skillForm.title === "Qualifikation hinzufügen") {
        this.addSkill();
      } else {
        this.updateSkill();
      }
    },
    // Creation
    addCourse() {
      const coursesRef = db
        .collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("personnelRecords_courses")
        .doc();
      coursesRef
        .set({
          organizationId: this.$route.params.organizationId,
          userId: this.$route.params.itemId,

          course: this.helpers.courseForm.input.course,
          externalName: this.helpers.courseForm.input.externalName,
          externalId: this.helpers.courseForm.input.externalId,
          duration: {
            startDate: this.convertToTimestamp(
              this.helpers.courseForm.input.duration.startDate,
              "00:00"
            ),
            endDate: this.convertToTimestamp(
              this.helpers.courseForm.input.duration.endDate,
              "00:00"
            ),
            proofDate: this.convertToTimestamp(
              this.helpers.courseForm.input.duration.proofDate,
              "00:00"
            ),
          },
          rating: this.helpers.courseForm.input.rating,
          note: this.helpers.courseForm.input.note,
          meta: {
            id: coursesRef.id,
            createdBy: auth.currentUser.uid,
            createdAt: FieldValue.serverTimestamp(),
          },
        })
        .then(() => {
          this.helpers.courseForm.isLoading = false;
          this.courses.push({
            meta: {
              id: coursesRef.id,
            },
            course: this.helpers.courseForm.input.course,
            externalName: this.helpers.courseForm.input.externalName,
            externalId: this.helpers.courseForm.input.externalId,
            duration: {
              startDate: this.convertToTimestamp(
                this.helpers.courseForm.input.duration.startDate,
                "00:00"
              ),
              endDate: this.convertToTimestamp(
                this.helpers.courseForm.input.duration.endDate,
                "00:00"
              ),
              proofDate: this.convertToTimestamp(
                this.helpers.courseForm.input.duration.proofDate,
                "00:00"
              ),
            },
            rating: this.helpers.courseForm.input.rating,
            note: this.helpers.courseForm.input.note,
          });
          this.toggleCourseFormDialog();
          this.helpers.snackbar = {
            model: true,
            message: "Lehrgang hinzugefügt.",
            type: "succcess",
          };
        })
        .catch((error) => {
          this.helpers.courseForm.errorMessage = handleFirestoreError(
            error.code
          );
          console.log(error.code, error.message);
          this.helpers.courseForm.isLoading = false;
        });
    },
    addSkill() {
      const skillsRef = db
        .collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("personnelRecords_skills")
        .doc();
      skillsRef
        .set({
          organizationId: this.$route.params.organizationId,
          userId: this.$route.params.itemId,

          skill: this.helpers.skillForm.input.skill,
          qualification: {
            since: this.helpers.skillForm.input.qualification.since
              ? this.convertToTimestamp(
                  this.helpers.skillForm.input.qualification.since,
                  "00:00"
                )
              : null,
            until: this.helpers.skillForm.input.qualification.until
              ? this.convertToTimestamp(
                  this.helpers.skillForm.input.qualification.until,
                  "00:00"
                )
              : null,
          },
          note: this.helpers.skillForm.input.note,
          meta: {
            id: skillsRef.id,
            createdBy: auth.currentUser.uid,
            createdAt: FieldValue.serverTimestamp(),
          },
        })
        .then(() => {
          this.helpers.skillForm.isLoading = false;
          this.skills.push({
            meta: {
              id: skillsRef.id,
            },
            skill: this.helpers.skillForm.input.skill,
            qualification: {
              since: this.helpers.skillForm.input.qualification.since
                ? this.convertToTimestamp(
                    this.helpers.skillForm.input.qualification.since,
                    "00:00"
                  )
                : null,
              until: this.helpers.skillForm.input.qualification.until
                ? this.convertToTimestamp(
                    this.helpers.skillForm.input.qualification.until,
                    "00:00"
                  )
                : null,
            },
            note: this.helpers.skillForm.input.note,
          });
          this.toggleSkillFormDialog();
          this.helpers.snackbar = {
            model: true,
            message: "Qualifikation hinzugefügt.",
            type: "succcess",
          };
        })
        .catch((error) => {
          this.helpers.skillForm.errorMessage = handleFirestoreError(
            error.code
          );
          console.log(error.code, error.message);
          this.helpers.skillForm.isLoading = false;
        });
    },

    // Prepare update
    editCourse(course) {
      this.helpers.courseForm.title = "Lehrgang bearbeiten";
      this.helpers.courseForm.btnText = "Speichern";
      this.helpers.courseForm.input = {
        id: course.meta.id,
        course: course.course,
        externalName: course.externalName,
        externalId: course.externalId,
        duration: {
          startDate: this.convertTimestampToDate(course.duration.startDate),
          endDate: this.convertTimestampToDate(course.duration.endDate),
          proofDate: this.convertTimestampToDate(course.duration.proofDate),
        },
        rating: course.rating,
        note: course.note,
      };
      this.helpers.courseForm.dialogModel =
        !this.helpers.courseForm.dialogModel;
    },
    editSkill(skill) {
      this.helpers.skillForm.title = "Qualifikation bearbeiten";
      this.helpers.skillForm.btnText = "Speichern";
      this.helpers.skillForm.input = {
        id: skill.meta.id,
        skill: skill.skill,
        qualification: {
          since: skill.qualification.since
            ? this.convertTimestampToDate(skill.qualification.since)
            : null,
          until: skill.qualification.until
            ? this.convertTimestampToDate(skill.qualification.until)
            : null,
        },
        note: skill.note,
      };
      this.helpers.skillForm.dialogModel = !this.helpers.skillForm.dialogModel;
    },

    // Updates
    updateCourse() {
      var course = {
        course: this.helpers.courseForm.input.course,
        externalName: this.helpers.courseForm.input.externalName,
        externalId: this.helpers.courseForm.input.externalId,
        duration: {
          startDate: this.convertToTimestamp(
            this.helpers.courseForm.input.duration.startDate,
            "00:00"
          ),
          endDate: this.convertToTimestamp(
            this.helpers.courseForm.input.duration.endDate,
            "00:00"
          ),
          proofDate: this.convertToTimestamp(
            this.helpers.courseForm.input.duration.proofDate,
            "00:00"
          ),
        },
        rating: this.helpers.courseForm.input.rating,
        note: this.helpers.courseForm.input.note,
        "meta.lastUpdatedAt": FieldValue.serverTimestamp(),
        "meta.lastUpdatedBy": auth.currentUser.uid,
      };

      db.collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("personnelRecords_courses")
        .doc(this.helpers.courseForm.input.id)
        .update(course)
        .then(() => {
          const index = this.courses.findIndex(
            (course) => course.meta.id === this.helpers.courseForm.input.id
          );
          this.courses[index] = course;

          this.helpers.courseForm.isLoading = false;
          this.toggleCourseFormDialog();
          this.helpers.snackbar = {
            model: true,
            message: "Lehrgang aktualisiert.",
            type: "succcess",
          };
        })
        .catch((error) => {
          console.log(error.code, error.message);
          this.helpers.courseForm.message = handleFirestoreError(error);
          this.helpers.courseForm.isLoading = false;
        });
    },
    updateSkill() {
      const skill = {
        skill: this.helpers.skillForm.input.skill,
        qualification: {
          since: this.helpers.skillForm.input.qualification.since
            ? this.convertToTimestamp(
                this.helpers.skillForm.input.qualification.since,
                "00:00"
              )
            : null,
          until: this.helpers.skillForm.input.qualification.until
            ? this.convertToTimestamp(
                this.helpers.skillForm.input.qualification.until,
                "00:00"
              )
            : null,
        },
        note: this.helpers.skillForm.input.note,
        "meta.lastUpdatedAt": FieldValue.serverTimestamp(),
        "meta.lastUpdatedBy": auth.currentUser.uid,
      };

      db.collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("personnelRecords_skills")
        .doc(this.helpers.skillForm.input.id)
        .update(skill)
        .then(() => {
          const index = this.skills.findIndex(
            (skill) => skill.meta.id === this.helpers.skillForm.input.id
          );
          this.skills[index] = skill;

          this.helpers.skillForm.isLoading = false;

          this.toggleSkillFormDialog();
          this.helpers.snackbar = {
            model: true,
            message: "Qualifikation aktualisiert.",
            type: "succcess",
          };
        })
        .catch((error) => {
          console.log(error.code, error.message);
          this.helpers.skillForm.message = handleFirestoreError(error);
          this.helpers.skillForm.isLoading = false;
        });
    },

    // Deletion
    deleteCourseSelection() {
      var res = confirm(
        "Bist Du sicher, dass Du die ausgewählten Lehrgänge löschen möchtest?\nDavon abhängige Qualifikationen werden nicht aktualisiert."
      );
      if (res == true) {
        this.helpers.courseSelection.forEach((course) => {
          this.deleteCourse(course, true);
        });
        this.helpers.courseSelection = [];
      } else {
        this.helpers.courseSelection = [];
      }
    },
    deleteSkillSelection() {
      var res = confirm(
        "Bist Du sicher, dass Du die ausgewählten Qualifikationen löschen möchtest?\nDavon abhängige Qualifikationen werden nicht aktualisiert."
      );
      if (res == true) {
        this.helpers.skillSelection.forEach((skill) => {
          this.deleteSkill(skill, true);
        });
        this.helpers.skillSelection = [];
      } else {
        this.helpers.skillSelection = [];
      }
    },
    deleteCourse(course, multiple = false) {
      if (!multiple) {
        var res = confirm(
          "Bist Du sicher, dass Du diesen Lehrgang löschen möchtest?\nDavon abhängige Qualifikationen werden nicht aktualisiert."
        );
      }
      if (multiple || res == true) {
        db.collection("organizations")
          .doc(this.$route.params.organizationId)
          .collection("personnelRecords_courses")
          .doc(course.meta.id)
          .delete()
          .then(() => {
            const index = this.courses.indexOf(course);
            this.courses.splice(index, 1);
            this.helpers.snackbar = {
              model: true,
              message: "Lehrgang gelöscht.",
              type: "succcess",
            };
          })
          .catch((error) => {
            console.log(error.code, error.message);
            this.helpers.snackbar = {
              model: true,
              message: handleFirestoreError(error.code),
              type: "error",
            };
          });
      }
    },
    deleteSkill(skill, multiple = false) {
      if (!multiple) {
        var res = confirm(
          "Bist Du sicher, dass Du diese Qualifikation löschen möchtest?"
        );
      }
      if (multiple || res == true) {
        db.collection("organizations")
          .doc(this.$route.params.organizationId)
          .collection("personnelRecords_skills")
          .doc(skill.meta.id)
          .delete()
          .then(() => {
            const index = this.skills.indexOf(skill);
            this.skills.splice(index, 1);
            this.helpers.snackbar = {
              model: true,
              message: "Qualifikation gelöscht.",
              type: "succcess",
            };
          })
          .catch((error) => {
            console.log(error.code, error.message);
            this.helpers.snackbar = {
              model: true,
              message: handleFirestoreError(error.code),
              type: "error",
            };
          });
      }
    },
  },
};
</script>

<style></style>
