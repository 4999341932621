<template>
  <div>
    <section>
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <div class="mt-7">
              <v-card>
                <personnel-record-emergency-contacts-data-table
                  :emergencyContacts="emergencyContacts"
                  mode="view"
                >
                </personnel-record-emergency-contacts-data-table>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section v-if="checkIfSupport">
      <support-tools :sources="code"></support-tools>
    </section>
  </div>
</template>

<script>
import { db } from "@/firebase";
import PersonnelRecordEmergencyContactsDataTable from "@/components/member-management/personnel-records/PersonnelRecordEmergencyContactsDataTable.vue";
import SupportTools from "@/components/_system/helpers/SupportTools.vue";
export default {
  name: "member-management-personnel-records-details-emergency-contacts",
  components: {
    PersonnelRecordEmergencyContactsDataTable,
    SupportTools,
  },
  data() {
    return {
      emergencyContacts: [],
    };
  },
  computed: {
    checkIfSupport() {
      return this.$store.getters["organization/checkIfSupport"];
    },
    code() {
      return [{ title: "emergencyContacts", data: this.emergencyContacts }];
    },
  },
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    initialize() {
      this.getEmergencyContacts();
    },
    getEmergencyContacts() {
      return db
        .collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("personnelRecords_emergencyContacts")
        .where("userId", "==", this.$route.params.itemId)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.emergencyContacts.push(doc.data());
          });
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
        });
    },
  },
};
</script>
