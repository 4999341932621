<template>
  <div>
    <section>
      <template>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12" md="5">
              <v-skeleton-loader
                v-if="dataIsLoading"
                elevation="2"
                type="card, list-item-avatar-three-line, list-item-avatar-three-line"
              ></v-skeleton-loader>
              <v-card v-else class="pt-4">
                <v-card-title class="justify-center flex-column">
                  <v-avatar
                    size="160"
                    :color="
                      $vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-3'
                    "
                    rounded
                    class="mb-2"
                  >
                    <!-- TODO: Correct photoUrl -> resolution  -->
                    <v-img
                      v-if="user.photoUrl"
                      contain
                      :src="user.photoUrl"
                    ></v-img>
                    <v-icon v-else size="82">mdi-account-circle</v-icon>
                  </v-avatar>

                  <span
                    >{{ user.title }} {{ user.firstName }}
                    {{ user.lastName }}</span
                  >

                  <personnel-record-status-chip
                    :status="user.status"
                    class="mt-2"
                  >
                  </personnel-record-status-chip>

                  <!-- <span class="body-2 text--secondary">{{
                    user.dutyPosition.title
                  }}</span> -->
                </v-card-title>

                <!-- <v-card-text class="justify-center mt-6">
                  <v-card outlined class="mb-4">
                    <v-card-text>
                      <div class="d-flex align-center me-8">
                        <v-avatar color="red lighten-5" rounded class="me-4">
                          <v-icon size="22" color="red"> mdi-circle </v-icon>
                        </v-avatar>

                        <div>
                          <h3 class="text-xl font-weight-medium mb-n1">
                            {{ getAge(user.birthdate) }}
                          </h3>
                          <span>Alter</span>
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>

                  <v-card outlined class="mb-4">
                    <v-card-text>
                      <div class="d-flex align-center me-8">
                        <div class="d-flex align-center me-4">
                          <v-avatar color="red lighten-5" rounded class="me-4">
                            <v-icon size="22" color="red">
                              mdi-timeline-clock
                            </v-icon>
                          </v-avatar>

                          <div>
                            <h3 class="text-xl font-weight-medium mb-n1">
                              {{ kFormatter(1201) }}
                            </h3>
                            <span>Dienstalter</span>
                          </div>
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-card-text> -->
              </v-card>
            </v-col>
            <v-col cols="12" sm="12" md="7">
              <v-skeleton-loader
                v-if="dataIsLoading"
                elevation="2"
                type="table-heading, table-tbody"
              ></v-skeleton-loader>
              <v-card v-else>
                <v-card-title class="body-2 v-card__subtitle"
                  ><v-icon left small class="mr-2"
                    >mdi-card-account-details</v-icon
                  >Allgemein</v-card-title
                >

                <v-divider></v-divider>
                <personnel-record-general-table
                  :user="user"
                ></personnel-record-general-table>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <section v-if="checkIfSupport">
      <support-tools :sources="code"></support-tools>
    </section>
  </div>
</template>

<script>
import { db } from "@/firebase";
import SupportTools from "@/components/_system/helpers/SupportTools.vue";
import PersonnelRecordStatusChip from "@/components/member-management/personnel-records/PersonnelRecordStatusChip.vue";
import PersonnelRecordGeneralTable from "@/components/member-management/personnel-records/PersonnelRecordGeneralTable.vue";
export default {
  name: "member-management-personnel-records-details-general",
  components: {
    PersonnelRecordStatusChip,
    PersonnelRecordGeneralTable,
    SupportTools,
  },
  data() {
    return {
      dataIsLoading: true,
      user: {
        id: null,
        salutation: null,
        letterSalutation: null,
        title: null,
        firstName: null,
        lastName: null,
        birthday: null,
        birthdate: null,
        photoUrl: null,

        memberSince: null,
        memberUntil: null,
        dutyPosition: {
          badgeUrl: {
            size70x70: null,
            size125x125: null,
          },
          title: null,
          since: null,
        },
        rank: {
          badgeUrl: {
            size70x70: null,
            size125x125: null,
          },
          title: null,
          since: null,
        },
        status: null,
        division: null,
      },
    };
  },
  computed: {
    checkIfSupport() {
      return this.$store.getters["organization/checkIfSupport"];
    },
    code() {
      return [{ title: "user", data: this.user }];
    },
  },
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    initialize() {
      db.collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("personnelRecords_general")
        .doc(this.$route.params.itemId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.user = doc.data();
            this.dataIsLoading = false;
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },
    kFormatter(num, fraction = 2) {
      return num > 999 ? `${(num / 1000).toFixed(fraction)}k` : num;
    },
  },
};
</script>
