<template>
  <div>
    <section>
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <div class="mt-7">
              <v-card>
                <personnel-record-emergency-contacts-data-table
                  v-model="helpers.emergencyContactSelection"
                  :emergencyContacts="emergencyContacts"
                  mode="manage"
                  @editEmergencyContact="editEmergencyContact"
                  @deleteEmergencyContact="deleteEmergencyContact"
                >
                  <template v-slot:top>
                    <v-card flat>
                      <v-card-title class="body-2 v-card__subtitle"
                        ><v-icon left small class="mr-2"
                          >mdi-shield-account</v-icon
                        >Notfallkontakte
                        <v-spacer></v-spacer>
                        <v-btn
                          depressed
                          :disabled="!helpers.emergencyContactSelection.length"
                          color="error"
                          class="mr-2"
                          @click="deleteEmergencyContactSelection()"
                          ><v-icon left>mdi-delete</v-icon>Auswahl löschen
                        </v-btn>
                        <v-btn
                          depressed
                          color="success"
                          @click="toggleEmergencyContactFormDialog()"
                          ><v-icon left>mdi-account-plus</v-icon>Hinzufügen
                        </v-btn>
                      </v-card-title>
                    </v-card>
                  </template>
                </personnel-record-emergency-contacts-data-table>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <custom-dialog
        v-if="helpers.emergencyContactForm.dialogModel"
        @close="toggleEmergencyContactFormDialog()"
        :title="helpers.emergencyContactForm.title"
        width="xl"
        :btnAction="emergencyContactAction"
        :btnText="helpers.emergencyContactForm.btnText"
        :loading="helpers.emergencyContactForm.isLoading"
        :errorMessage="helpers.emergencyContactForm.errorMessage"
        :btnDisabled="!helpers.emergencyContactForm.formIsValid"
        btnColor="success"
      >
        <template v-slot:content>
          <v-form
            v-model="helpers.emergencyContactForm.formIsValid"
            @submit.prevent="emergencyContactAction()"
            class="mt-2"
          >
            <v-row>
              <v-col cols="12" sm="12" md="8">
                <v-card outlined>
                  <v-card-subtitle
                    ><v-icon left small class="mr-2"
                      >mdi-card-account-details</v-icon
                    >Allgemein</v-card-subtitle
                  >
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model.trim="
                            helpers.emergencyContactForm.input.firstName
                          "
                          label="Vorname"
                          :rules="[rules.required]"
                          hide-details="auto"
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model.trim="
                            helpers.emergencyContactForm.input.lastName
                          "
                          label="Nachname"
                          :rules="[rules.required]"
                          hide-details="auto"
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <v-select
                          v-model="
                            helpers.emergencyContactForm.input.relationship
                          "
                          label="Beziehung"
                          :items="relationshipOptions"
                          :rules="[rules.required]"
                          hide-details="auto"
                          outlined
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="8">
                        <v-textarea
                          v-model.trim="helpers.emergencyContactForm.input.note"
                          label="Bemerkung"
                          hide-details="auto"
                          outlined
                          rows="1"
                          auto-grow
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="6">
                <v-card outlined>
                  <v-card-subtitle
                    ><v-icon left small class="mr-2">mdi-phone</v-icon
                    >Erreichbarkeit</v-card-subtitle
                  >
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model.trim="
                            helpers.emergencyContactForm.input.phone
                          "
                          label="Telefon"
                          hide-details="auto"
                          :rules="[rules.mobile]"
                          outlined
                          prepend-inner-icon="mdi-phone"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model.trim="
                            helpers.emergencyContactForm.input.mobile
                          "
                          label="Mobil"
                          hide-details="auto"
                          :rules="[rules.mobile]"
                          outlined
                          prepend-inner-icon="mdi-cellphone"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12">
                        <v-text-field
                          v-model.trim="
                            helpers.emergencyContactForm.input.email
                          "
                          label="E-Mail"
                          hide-details="auto"
                          :rules="[rules.email]"
                          prepend-inner-icon="mdi-at"
                          outlined
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-card outlined>
                  <v-card-subtitle
                    ><v-icon left small class="mr-2">mdi-map-marker</v-icon
                    >Adressangaben</v-card-subtitle
                  >
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" sm="8">
                        <v-text-field
                          v-model.trim="
                            helpers.emergencyContactForm.input.address.street
                          "
                          label="Straße"
                          hide-details="auto"
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" sm="4">
                        <v-text-field
                          v-model.trim="
                            helpers.emergencyContactForm.input.address
                              .streetNumber
                          "
                          label="Nr."
                          hide-details="auto"
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" sm="3">
                        <v-text-field
                          v-model.trim="
                            helpers.emergencyContactForm.input.address
                              .postalCode
                          "
                          label="Postleitzahl"
                          hide-details="auto"
                          :rules="[rules.postalCode]"
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" sm="4">
                        <v-text-field
                          v-model.trim="
                            helpers.emergencyContactForm.input.address.city
                          "
                          label="Stadt"
                          hide-details="auto"
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" sm="5">
                        <v-text-field
                          v-model.trim="
                            helpers.emergencyContactForm.input.address.country
                          "
                          label="Land"
                          hide-details="auto"
                          outlined
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-form>
        </template>
      </custom-dialog>
      <v-snackbar
        v-model="helpers.snackbar.model"
        class="pb-4"
        bottom
        app
        timeout="2000"
        ><v-icon
          :color="helpers.snackbar.type === 'success' ? 'success' : 'error'"
          class="mr-1"
          >{{
            helpers.snackbar.type === "success"
              ? "mdi-check-circle"
              : "mdi-error"
          }}</v-icon
        >
        {{ helpers.snackbar.message }}
      </v-snackbar>
    </section>
  </div>
</template>

<script>
import { db, FieldValue, auth } from "@/firebase";
import { handleFirestoreError } from "@/util/firebase/FirebaseErrorHandling.js";
import CustomDialog from "@/components/_systemwide/CustomDialog.vue";
import PersonnelRecordEmergencyContactsDataTable from "@/components/member-management/personnel-records/PersonnelRecordEmergencyContactsDataTable.vue";
export default {
  name: "member-management-personnel-records-details-emergency-contacts",
  components: {
    CustomDialog,
    PersonnelRecordEmergencyContactsDataTable,
  },
  data() {
    return {
      helpers: {
        snackbar: {
          model: false,
          type: "success",
          message: "",
        },
        emergencyContactSelection: [],
        emergencyContactForm: {
          title: "Notfallkontakt hinzufügen",
          btnText: "Hinzufügen",
          dialogModel: false,
          formIsValid: false,
          isLoading: false,
          errorMessage: "",
          input: {
            firstName: null,
            lastName: null,
            relationship: null,
            note: null,
            phone: null,
            mobile: null,
            email: null,
            address: {
              street: null,
              streetNumber: null,
              postalCode: null,
              city: null,
              country: null,
            },
          },
        },
      },
      relationshipOptions: [
        { text: "Elternteil", value: "parent" },
        { text: "Ehepartner", value: "spouse" },
        { text: "Kind", value: "child" },
        { text: "Geschwister", value: "sibling" },
        { text: "Freund", value: "friend" },
        { text: "Arbeitskollege", value: "colleague" },
        { text: "Nachbar", value: "neighbor" },
        { text: "Sonstige", value: "other" },
      ],
      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
        email: (value) => {
          if (!value) return true;
          const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
          return pattern.test(value) || "Ungültige E-Mail-Adresse.";
        },
        mobile: (value) => {
          if (!value) return true;
          const pattern = /^[0-9]{10,14}$/;
          return pattern.test(value) || "Ungültige Telefonnummer.";
        },
        postalCode: (value) => {
          if (!value) return true;
          const pattern = /^[0-9]{5}$/;
          return pattern.test(value) || "Ungültige Postleitzahl.";
        },
      },
      emergencyContacts: [],
    };
  },
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    initialize() {
      this.getEmergencyContacts();
    },
    getEmergencyContacts() {
      return db
        .collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("personnelRecords_emergencyContacts")
        .where("userId", "==", this.$route.params.itemId)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.emergencyContacts.push(doc.data());
          });
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
        });
    },
    toggleEmergencyContactFormDialog() {
      this.helpers.emergencyContactForm.title = "Notfallkontakt hinzufügen";
      this.helpers.emergencyContactForm.btnText = "Hinzufügen";
      this.helpers.emergencyContactForm.errorMessage = "";
      this.helpers.emergencyContactForm.input = {
        firstName: null,
        lastName: null,
        relationship: null,
        note: null,
        phone: null,
        mobile: null,
        email: null,
        address: {
          street: null,
          streetNumber: null,
          postalCode: null,
          city: null,
          country: null,
        },
      };
      this.helpers.emergencyContactForm.dialogModel =
        !this.helpers.emergencyContactForm.dialogModel;
    },
    emergencyContactAction() {
      this.helpers.emergencyContactForm.isLoading = true;
      if (
        this.helpers.emergencyContactForm.title === "Notfallkontakt hinzufügen"
      ) {
        this.addEmergencyContact();
      } else {
        this.updateEmergencyContact();
      }
    },

    // Creation
    addEmergencyContact() {
      const emergencyContactsRef = db
        .collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("personnelRecords_emergencyContacts")
        .where("userId", "==", this.$route.params.itemId)
        .doc();
      emergencyContactsRef
        .set({
          organizationId: this.$route.params.organizationId,
          userId: this.$route.params.itemId,

          firstName: this.helpers.emergencyContactForm.input.firstName,
          lastName: this.helpers.emergencyContactForm.input.lastName,
          relationship: this.helpers.emergencyContactForm.input.relationship,
          note: this.helpers.emergencyContactForm.input.note,
          phone: this.helpers.emergencyContactForm.input.phone,
          mobile: this.helpers.emergencyContactForm.input.mobile,
          email: this.helpers.emergencyContactForm.input.email,
          address: {
            street: this.helpers.emergencyContactForm.input.address.street,
            streetNumber:
              this.helpers.emergencyContactForm.input.address.streetNumber,
            postalCode:
              this.helpers.emergencyContactForm.input.address.postalCode,
            city: this.helpers.emergencyContactForm.input.address.city,
            country: this.helpers.emergencyContactForm.input.address.country,
          },

          meta: {
            id: emergencyContactsRef.id,
            createdBy: auth.currentUser.uid,
            createdAt: FieldValue.serverTimestamp(),
          },
        })
        .then(() => {
          this.helpers.emergencyContactForm.isLoading = false;
          this.emergencyContacts.push({
            firstName: this.helpers.emergencyContactForm.input.firstName,
            lastName: this.helpers.emergencyContactForm.input.lastName,
            relationship: this.helpers.emergencyContactForm.input.relationship,
            note: this.helpers.emergencyContactForm.input.note,
            phone: this.helpers.emergencyContactForm.input.phone,
            mobile: this.helpers.emergencyContactForm.input.mobile,
            email: this.helpers.emergencyContactForm.input.email,
            address: {
              street: this.helpers.emergencyContactForm.input.address.street,
              streetNumber:
                this.helpers.emergencyContactForm.input.address.streetNumber,
              postalCode:
                this.helpers.emergencyContactForm.input.address.postalCode,
              city: this.helpers.emergencyContactForm.input.address.city,
              country: this.helpers.emergencyContactForm.input.address.country,
            },
            meta: {
              id: emergencyContactsRef.id,
            },
          });
          this.toggleEmergencyContactFormDialog();
          this.helpers.snackbar = {
            model: true,
            message: "Notfallkontakt hinzugefügt.",
            type: "succcess",
          };
        })
        .catch((error) => {
          this.helpers.emergencyContactForm.errorMessage = handleFirestoreError(
            error.code
          );
          console.log(error.code, error.message);
          this.helpers.emergencyContactForm.isLoading = false;
        });
    },

    // Prepare update
    editEmergencyContact(emergencyContact) {
      this.helpers.emergencyContactForm.title = "Notfallkontakt bearbeiten";
      this.helpers.emergencyContactForm.btnText = "Speichern";
      this.helpers.emergencyContactForm.input = {
        id: emergencyContact.meta.id,
        firstName: emergencyContact.firstName,
        lastName: emergencyContact.lastName,
        relationship: emergencyContact.relationship,
        note: emergencyContact.note,
        phone: emergencyContact.phone,
        mobile: emergencyContact.mobile,
        email: emergencyContact.email,
        address: {
          street: emergencyContact.address.street,
          streetNumber: emergencyContact.address.streetNumber,
          postalCode: emergencyContact.address.postalCode,
          city: emergencyContact.address.city,
          country: emergencyContact.address.country,
        },
      };
      this.helpers.emergencyContactForm.dialogModel =
        !this.helpers.emergencyContactForm.dialogModel;
    },
    // Update
    updateEmergencyContact() {
      var emergencyContact = {
        firstName: this.helpers.emergencyContactForm.input.firstName,
        lastName: this.helpers.emergencyContactForm.input.lastName,
        relationship: this.helpers.emergencyContactForm.input.relationship,
        note: this.helpers.emergencyContactForm.input.note,
        phone: this.helpers.emergencyContactForm.input.phone,
        mobile: this.helpers.emergencyContactForm.input.mobile,
        email: this.helpers.emergencyContactForm.input.email,
        address: {
          street: this.helpers.emergencyContactForm.input.address.street,
          streetNumber:
            this.helpers.emergencyContactForm.input.address.streetNumber,
          postalCode:
            this.helpers.emergencyContactForm.input.address.postalCode,
          city: this.helpers.emergencyContactForm.input.address.city,
          country: this.helpers.emergencyContactForm.input.address.country,
        },
        "meta.lastUpdatedAt": FieldValue.serverTimestamp(),
        "meta.lastUpdatedBy": auth.currentUser.uid,
      };

      db.collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("personnelRecords_emergencyContacts")
        .doc(this.helpers.emergencyContactForm.input.id)
        .update(emergencyContact)
        .then(() => {
          const index = this.emergencyContacts.findIndex(
            (emergencyContact) =>
              emergencyContact.meta.id ===
              this.helpers.emergencyContactForm.input.id
          );
          console.log(index);
          this.emergencyContacts[index] = emergencyContact;

          this.helpers.emergencyContactForm.isLoading = false;
          this.toggleEmergencyContactFormDialog();
          this.helpers.snackbar = {
            model: true,
            message: "Notfallkontakt aktualisiert.",
            type: "succcess",
          };
        })
        .catch((error) => {
          console.log(error.code, error.message);
          this.helpers.emergencyContactForm.message =
            handleFirestoreError(error);
          this.helpers.emergencyContactForm.isLoading = false;
        });
    },

    // Deletion
    deleteEmergencyContactSelection() {
      var res = confirm(
        "Bist Du sicher, dass Du die ausgewählten Notfallkontakte löschen möchtest?"
      );
      if (res == true) {
        this.helpers.emergencyContactSelection.forEach((emergencyContact) => {
          this.deleteEmergencyContact(emergencyContact, true);
        });
        this.helpers.emergencyContactSelection = [];
      } else {
        this.helpers.emergencyContactSelection = [];
      }
    },
    deleteEmergencyContact(emergencyContact, multiple = false) {
      if (!multiple) {
        var res = confirm(
          "Bist Du sicher, dass Du diesen Notfallkontakt löschen möchtest"
        );
      }
      if (multiple || res == true) {
        db.collection("organizations")
          .doc(this.$route.params.organizationId)
          .collection("personnelRecords_emergencyContacts")
          .doc(emergencyContact.meta.id)
          .delete()
          .then(() => {
            const index = this.emergencyContacts.indexOf(emergencyContact);
            this.emergencyContacts.splice(index, 1);
            this.helpers.snackbar = {
              model: true,
              message: "Notfallkontakt gelöscht.",
              type: "succcess",
            };
          })
          .catch((error) => {
            console.log(error.code, error.message);
            this.helpers.snackbar = {
              model: true,
              message: handleFirestoreError(error.code),
              type: "error",
            };
          });
      }
    },
  },
};
</script>

<style></style>
