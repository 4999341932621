<template>
  <div>
    <section>
      <template>
        <v-container fluid>
          <v-row class="match-height">
            <v-col>
              <NavigationBarNext
                titel="Details"
                :actions="actions"
                :backRoute="backRoute"
                :tabs="tabs"
                :activeTab="activeTab"
                @active-tab-updated="activeTab = $event"
                showBackBtn
              ></NavigationBarNext>
            </v-col>
            <v-col cols="12">
              <v-toolbar outlined rounded>
                <v-btn text outlined><v-icon>mdi-arrow-left</v-icon></v-btn>
                <span class="ml-6"
                  ><small class="mb-0 pb-0 grey--text">Personaldaten</small>
                  <div class="mt-0 pt-0 title font-weight-medium">
                    Vorname Nachname
                  </div></span
                >
                <v-spacer></v-spacer>
              </v-toolbar>
            </v-col>
            <v-col cols="12" class="mx-0 px-0 pb-0 mb-0">
              <v-toolbar
                outlined
                rounded
                elevation="0"
                class="transparent mx-n1 px-n1"
              >
                <v-btn
                  light
                  color="white"
                  style="
                    max-width: 40px !important;
                    min-width: none !important;
                    max-height: 48px !important;
                    height: 48px !important;
                  "
                  class="px-6"
                  ><v-icon class="">mdi-arrow-left</v-icon></v-btn
                >
                <span class="ml-6 mr-4">
                  <div class="mt-0 pt-0 title font-weight-medium">
                    Vorname Nachname
                  </div></span
                >
                <v-btn class="white ml-2" small
                  ><v-icon small left>mdi-email</v-icon
                  >vorname.nachname@musterstadt-feuerwehr.de</v-btn
                >

                <v-spacer></v-spacer>
                <v-btn
                  color="white"
                  class="uppercase ml-2 grey--text text--darken-2"
                  small
                >
                  <v-icon left class="grey--text text--darken-2" dense
                    >mdi-pencil</v-icon
                  >
                  <span>Bearbeiten</span>
                </v-btn>
                <v-btn color="white" class="uppercase ml-2">
                  <v-icon left>mdi-dots-horizontal-circle</v-icon>
                  <span>Aktionen</span>
                </v-btn>
              </v-toolbar>
              <v-breadcrumbs :items="items">
                <template v-slot:divider>
                  <v-icon class="mx-0 px-0">mdi-chevron-right</v-icon>
                </template>
              </v-breadcrumbs>
            </v-col>
            <v-col cols="7">
              <v-card outlined>
                <slot name="tab-bar">
                  <div v-if="!!tabs">
                    <v-tabs
                      v-model="activeTab"
                      show-arrows
                      grow
                      class="rounded-t-lg"
                    >
                      <v-tab
                        v-for="tab of tabs"
                        :key="tab.id"
                        :to="tab.route"
                        :disabled="tab.disabled"
                        exact-path
                      >
                        {{ tab.name }}
                      </v-tab>
                      <v-tab-item
                        v-for="tab of tabs"
                        :key="tab.id"
                        :value="tab.route"
                        :disabled="tab.disabled"
                      >
                      </v-tab-item>
                    </v-tabs>
                  </div>
                </slot>
                <v-divider></v-divider>
                <!-- <router-view /> -->
              </v-card>
            </v-col>
            <v-col cols="5">
              <v-card outlined>
                <v-card-text> Weiterer Content </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
  </div>
</template>

<script>
import NavigationBarNext from "@/components/_systemwide/NavigationBarNext.vue";
// import Subheader from "@/components/_systemwide/Subheader.vue";

import {
  MEMBER_MANAGEMENT_PERSONNEL_RECORDS_update,
  MEMBER_MANAGEMENT_PERSONNEL_RECORDS_GENERAL_update,
  MEMBER_MANAGEMENT_PERSONNEL_RECORDS_CONTACT_update,
  MEMBER_MANAGEMENT_PERSONNEL_RECORDS_CHRONICLE_update,
  MEMBER_MANAGEMENT_PERSONNEL_RECORDS_CERTIFICATES_update,
  MEMBER_MANAGEMENT_PERSONNEL_RECORDS_PHYSICALS_update,
  MEMBER_MANAGEMENT_PERSONNEL_RECORDS_EMERGENCY_CONTACTS_update,
  MEMBER_MANAGEMENT_PERSONNEL_RECORDS_ACCOUNTING_update,
  MEMBER_MANAGEMENT_PERSONNEL_RECORDS_YOUTH_GROUP_update,
} from "@/data/permission-types.js";

export default {
  name: "member-management-personnel-records-details-next",
  components: {
    NavigationBarNext,
    // Subheader,
  },
  data() {
    return {
      items: [
        {
          text: "Mustersche Feuerwehr",
          disabled: true,
        },
        {
          text: "EB E X",
          disabled: false,
        },
        {
          text: "FF XX01",
          disabled: false,
        },
        {
          text: "Einsatzabteilung",
          disabled: false,
        },
      ],
      actions: [
        {
          title: "Bearbeiten",
          permissions: `${MEMBER_MANAGEMENT_PERSONNEL_RECORDS_update}`,
          icon: "mdi-pencil",
          actionStyle: "textBtn",
          function: this.editItem,
        },
        {
          title: "Aktionen",
          icon: "mdi-dots-horizontal-circle",
          actionStyle: "textBtnMultiple",
          list: [
            {
              title: "E-Mail senden",
              icon: "mdi-email",
              disabled: true,
              function: this.createItem,
            },
            {
              title: "Datensatz sperren",
              icon: "mdi-lock",
              disabled: true,
              function: this.createMultiple,
            },
            { divider: true },
            {
              title: "Kontakt als vCard exportieren ",
              icon: "mdi-file-export",
              disabled: true,
              function: this.createFromCSV,
            },
            {
              title: "Drucken",
              icon: "mdi-printer",
              disabled: true,
              function: this.printItem,
            },
          ],
        },

        { divider: true },
        { actionStyle: "clipboardBtn" },
      ],

      activeTab: `general`,
    };
  },
  computed: {
    tabs() {
      const allTabs = [
        {
          id: 1,
          name: "Stammdaten",
          route: `general`,
          permission: `${MEMBER_MANAGEMENT_PERSONNEL_RECORDS_GENERAL_update}`,
        },
        {
          id: 2,
          name: "Kontaktdaten",
          route: `contact`,
          permission: `${MEMBER_MANAGEMENT_PERSONNEL_RECORDS_CONTACT_update}`,
        },
        {
          id: 3,
          name: "Chronik",
          route: `chronicle`,
          permission: `${MEMBER_MANAGEMENT_PERSONNEL_RECORDS_CHRONICLE_update}`,
        },
        {
          id: 4,
          name: "Fristen",
          route: `deadlines`,
          disabled: true,
          permission: `${MEMBER_MANAGEMENT_PERSONNEL_RECORDS_CHRONICLE_update}`,
        },
        {
          id: 5,
          name: "Fertigkeiten",
          route: `certificates`,
          permission: `${MEMBER_MANAGEMENT_PERSONNEL_RECORDS_CERTIFICATES_update}`,
        },
        {
          id: 6,
          name: "Untersuchungen",
          route: `physicals`,
          disabled: true,
          permission: `${MEMBER_MANAGEMENT_PERSONNEL_RECORDS_PHYSICALS_update}`,
        },
        {
          id: 7,
          name: "Notfallkontakte",
          route: `emergency-contacts`,
          permission: `${MEMBER_MANAGEMENT_PERSONNEL_RECORDS_EMERGENCY_CONTACTS_update}`,
        },
        { id: 11, name: "Inventar", route: `inventory`, disabled: true },
        {
          id: 12,
          name: "Abrechnung",
          route: `accounting`,
          disabled: true,
          permission: `${MEMBER_MANAGEMENT_PERSONNEL_RECORDS_ACCOUNTING_update}`,
        },
        {
          id: 13,
          name: "Jugendfeuerwehr",
          route: `youth-group`,
          collectionId: "personnelRecords_youthGroup",
          disabled: true,
          permission: `${MEMBER_MANAGEMENT_PERSONNEL_RECORDS_YOUTH_GROUP_update}`,
        },
        { id: 99, name: "Änderungshistorie", route: ``, disabled: true },
      ];
      return allTabs.filter((tab) => {
        return this.checkPermission(tab.permission);
      });
    },
    backRoute() {
      return this.$route.path.includes ===
        "/personnel-records/" + this.$route.params.itemId
        ? "member-management-personnel-records"
        : "";
    },
  },
  methods: {
    editItem() {
      this.$router.push({
        name: `member-management-personnel-records-edit-${
          this.activeTab || "general"
        }`,
        params: {
          organizationId: this.$route.params.organizationId,
          itemId: this.$route.params.itemId,
        },
      });
    },
    checkPermission(item) {
      if (item == "" || item == undefined) {
        return true;
      } else {
        return this.$store.getters["organization/checkPermissionByID"](item);
      }
    },
  },
};
</script>

<style></style>
