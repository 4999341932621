<template>
  <div>
    <section>
      <template>
        <v-container fluid>
          <v-row class="match-height">
            <v-col cols="12" md="6" sm="12">
              <v-card>
                <v-card-title class="body-2 v-card__subtitle"
                  ><v-icon left small class="mr-2">mdi-label</v-icon
                  >Qualifkationsübersicht
                </v-card-title>
                <v-card-text>
                  <div v-if="!relevantSkills.length">
                    Keine Qualifikationen zugewiesen
                  </div>

                  <data-chip
                    v-else
                    v-for="(skill, index) in relevantSkills"
                    :key="index"
                    :color="skill.group.color"
                    :iconLeft="skill.group.icon"
                    :text="skill.title"
                    class="mr-2 mb-2"
                  >
                  </data-chip>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="6" sm="12">
              <v-card>
                <v-card-title class="body-2 v-card__subtitle"
                  ><v-icon left small class="mr-2">mdi-tag</v-icon>Besetzbare
                  Funktionen
                </v-card-title>
                <v-card-text>
                  <div v-if="!fillablePositions.length">
                    Keine Funktionen besetzbar
                  </div>
                  <v-chip
                    v-else
                    v-for="(position, index) in fillablePositions"
                    :key="index"
                    label
                    class="font-weight-medium text--darken-4 mr-2 mb-2"
                    v-bind:style="createBorderColorStyle(position.color)"
                    >{{ position.title }}</v-chip
                  >
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col v-if="showDeadlines" cols="12">
              <v-card>
                <personnel-record-deadlines-data-table
                  :deadlines="deadlines"
                ></personnel-record-deadlines-data-table> </v-card
            ></v-col>

            <v-col cols="12">
              <v-card>
                <personnel-record-skills-data-table
                  :skills="skills"
                  mode="view"
                >
                </personnel-record-skills-data-table>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card>
                <personnel-record-courses-data-table
                  :courses="courses"
                  mode="view"
                >
                </personnel-record-courses-data-table>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
  </div>
</template>

<script>
import { db } from "@/firebase";
import PersonnelRecordDeadlinesDataTable from "@/components/member-management/personnel-records/PersonnelRecordDeadlinesDataTable.vue";
import PersonnelRecordCoursesDataTable from "@/components/member-management/personnel-records/PersonnelRecordCoursesDataTable.vue";
import PersonnelRecordSkillsDataTable from "@/components/member-management/personnel-records/PersonnelRecordSkillsDataTable.vue";
import DataChip from "@/components/_systemwide/DataChip.vue";
import { AVAILABILITY, MEMBER_MANAGEMENT } from "@/store/modules.js";
import { GET_POSITIONS, GET_SKILLS } from "@/store/action-types.js";
export default {
  name: "member-management-personnel-records-details-certificates",
  components: {
    DataChip,
    PersonnelRecordDeadlinesDataTable,
    PersonnelRecordCoursesDataTable,
    PersonnelRecordSkillsDataTable,
  },
  data() {
    return {
      courses: [],
      skills: [],
      deadlines: [],
      showDeadlines: false,
    };
  },
  created() {
    this.initialize();
    this.deadlines = [
      {
        type: "G26.3",
        deadline: null,
      },
      {
        type: "Ausbildung",
        deadline: null,
      },
      {
        type: "ANT-Nachweis (Training)",
        deadline: null,
      },
    ];
  },
  computed: {
    positions() {
      const positions = this.$store.state.availability.positions;
      return positions.sort((a, b) => a.priority - b.priority);
    },
    skillGroups() {
      const skillGroups = this.$store.state.memberManagement.skillGroups;
      return skillGroups.sort((a, b) => a.sortKey - b.sortKey);
    },
    allSkills() {
      return this.$store.state.memberManagement.skills;
    },
    fillablePositions() {
      const allPositions = this.positions;

      const validSkillIds = this.skills.reduce((acc, skill) => {
        if (
          this.getSkillStatus(
            skill.qualification.since,
            skill.qualification.until
          ) === "active"
        ) {
          acc.push(skill.skill.id);
        }
        return acc;
      }, []);

      const fillablePositions = allPositions.filter((position) => {
        const conjunctionType = position.preconditions.conjunctionType;
        const requiredSkillIds = position.preconditions.skillIds;

        if (conjunctionType === "AND") {
          return requiredSkillIds.every((skillId) =>
            validSkillIds.includes(skillId)
          );
        } else if (conjunctionType === "OR") {
          return requiredSkillIds.some((skillId) =>
            validSkillIds.includes(skillId)
          );
        } else {
          return false;
        }
      });

      return fillablePositions;
    },
    relevantSkills() {
      // Step 1: Filter out the active skills
      const activeSkills = this.skills.filter(
        (skill) =>
          this.getSkillStatus(
            skill.qualification.since,
            skill.qualification.until
          ) === "active"
      );

      // Step 2: Group the active skills by their group.id
      const skillGroups = new Map();
      activeSkills.forEach((skill) => {
        const groupId = skill.skill.group.id;
        if (!skillGroups.has(groupId)) {
          skillGroups.set(groupId, []);
        }
        skillGroups.get(groupId).push(skill);
      });

      // Step 3: For each group, add the relevant skills to relevantSkills
      let relevantSkills = [];
      for (let skills of skillGroups.values()) {
        if (skills[0].skill.group.skillsAreEqual) {
          relevantSkills.push(...skills);
        } else {
          const highestSortKeySkill = skills.reduce((highest, skill) =>
            highest.skill.sortKey > skill.skill.sortKey ? highest : skill
          );
          relevantSkills.push(highestSortKeySkill);
        }
      }

      // Step 4: Sort relevantSkills by sortKey
      relevantSkills.sort(
        (a, b) => a.skill.group.sortKey - b.skill.group.sortKey
      );

      return relevantSkills.map((skill) => skill.skill);
    },
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    getSkillStatus(qualificationSince, qualificationUntil) {
      const currentDate = new Date();
      const sinceDate = qualificationSince ? qualificationSince.toDate() : null;
      const untilDate = qualificationUntil ? qualificationUntil.toDate() : null;

      if (sinceDate && sinceDate > currentDate) {
        return "upcoming";
      } else if (untilDate && untilDate < currentDate) {
        return "expired";
      } else {
        return "active";
      }
    },
    createBorderColorStyle(color) {
      const style = "border-left: 12px solid " + color;
      return style;
    },
    initialize() {
      this.getCourses();
      this.getSkills();
      this.$store.dispatch(`${AVAILABILITY}${GET_POSITIONS}`, {
        organizationId: this.$route.params.organizationId,
      });
      this.$store.dispatch(`${MEMBER_MANAGEMENT}${GET_SKILLS}`, {
        organizationId: this.$route.params.organizationId,
      });
    },
    getCourses() {
      return db
        .collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("personnelRecords_courses")
        .where("userId", "==", this.$route.params.itemId)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.courses.push(doc.data());
          });
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
        });
    },
    getSkills() {
      return db
        .collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("personnelRecords_skills")
        .where("userId", "==", this.$route.params.itemId)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.skills.push(doc.data());
          });
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
        });
    },
  },
};
</script>

<style></style>
