<template>
  <div>
    <v-snackbar v-model="snackbar" timeout="2000" bottom app
      >{{ this.snackbarText }}
      <!-- <template v-slot:action="{ attrs }">
        <v-btn color="" text v-bind="attrs" @click="snackbar = false">
          Schließen
        </v-btn>
      </template> -->
    </v-snackbar>
    <v-container fluid>
      <v-row class="child-flex">
        <v-col>
          <v-card>
            <v-toolbar
              fixed
              flat
              :dense="dense"
              :style="{
                'border-bottom-width': 'thin !important',
                'border-color': $vuetify.theme.dark
                  ? 'rgb(255,255,255,0.12)'
                  : 'rgb(0,0,0,0.12)',
                'border-bottom-style': 'solid !important',
              }"
            >
              <v-btn v-if="showBackBtn" icon @click="backBtnClicked()">
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
              <v-btn
                v-if="showDiscardBtn"
                @click="discardBtnAction || backBtnClicked()"
                icon
                color="error"
                class="hidden-md-and-up uppercase"
              >
                <v-icon>mdi-close-circle</v-icon>
              </v-btn>
              <v-btn
                v-if="showDiscardBtn"
                @click="discardBtnAction || backBtnClicked()"
                depressed
                color="error"
                class="mr-4 hidden-sm-and-down uppercase"
              >
                <v-icon left>mdi-close-circle</v-icon>Verwerfen
              </v-btn>
              <v-toolbar-title>{{ titel }}</v-toolbar-title>
              <v-chip
                color="teal lighten-5"
                v-if="isPreview"
                class="ml-4 font-weight-medium teal--text text--darken-5"
                ><v-icon left small>mdi-flask-empty</v-icon>Vorschau</v-chip
              >
              <v-spacer></v-spacer>
              <slot name="before-actions"></slot>
              <slot name="actions">
                <div v-for="(item, index) in actions" :key="index">
                  <div v-if="item.actionStyle === 'textBtn'">
                    <v-btn
                      v-if="
                        item.customPermission ||
                        checkPermission(item.permission)
                      "
                      text
                      :disabled="item.disabled"
                      :color="$vuetify.theme.dark ? '' : 'grey darken-1'"
                      class="hidden-sm-and-down uppercase"
                      @click="item.function"
                    >
                      <v-icon left>{{ item.icon }}</v-icon>
                      <span>{{ item.title }}</span>
                    </v-btn>
                    <v-btn
                      v-if="
                        item.customPermission ||
                        checkPermission(item.permission)
                      "
                      icon
                      text
                      :disabled="item.disabled"
                      :color="$vuetify.theme.dark ? '' : 'grey darken-1'"
                      class="hidden-md-and-up"
                      @click="item.function"
                    >
                      <v-icon>{{ item.icon }}</v-icon>
                    </v-btn>
                  </div>
                  <div v-else-if="item.actionStyle === 'iconBtn'">
                    <v-btn
                      v-if="
                        item.customPermission ||
                        checkPermission(item.permission)
                      "
                      icon
                      :disabled="item.disabled"
                      :color="$vuetify.theme.dark ? '' : 'grey darken-1'"
                      class="hidden-sm-and-down"
                      @click="item.function"
                    >
                      <v-icon>{{ item.icon }}</v-icon>
                    </v-btn>
                    <v-btn
                      v-if="
                        item.customPermission ||
                        checkPermission(item.permission)
                      "
                      icon
                      :disabled="item.disabled"
                      color="success"
                      class="hidden-md-and-up"
                      @click="item.function"
                    >
                      <v-icon>{{ item.icon }}</v-icon>
                    </v-btn>
                  </div>
                  <div v-else-if="item.actionStyle === 'textBtnSubmit'">
                    <v-btn
                      v-if="
                        item.customPermission ||
                        checkPermission(item.permission)
                      "
                      depressed
                      :disabled="item.disabled"
                      :color="item.color || 'success'"
                      class="hidden-sm-and-down uppercase mr-1"
                      @click="item.function"
                    >
                      <v-icon left>{{ item.icon }}</v-icon>
                      <span>{{ item.title }}</span>
                    </v-btn>
                    <v-btn
                      v-if="
                        item.customPermission ||
                        checkPermission(item.permission)
                      "
                      icon
                      :disabled="item.disabled"
                      :color="item.color || 'success'"
                      class="hidden-md-and-up"
                      @click="item.function"
                    >
                      <v-icon>{{ item.icon }}</v-icon>
                    </v-btn>
                  </div>
                  <div v-else-if="item.actionStyle === 'clipboardBtn'">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          :disabled="item.disabled"
                          v-bind="attrs"
                          v-on="on"
                          @click="copyToCLipboard()"
                        >
                          <v-icon>mdi-link</v-icon>
                        </v-btn>
                      </template>
                      <span>Link in Zwischenablage kopieren</span>
                    </v-tooltip>
                  </div>
                  <div v-else-if="item.actionStyle === 'textBtnMultiple'">
                    <v-menu
                      offset-y
                      v-if="
                        item.customPermission ||
                        checkPermission(item.permission)
                      "
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          text
                          :disabled="item.disabled"
                          class="hidden-sm-and-down uppercase"
                          :color="$vuetify.theme.dark ? '' : 'grey darken-1'"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon left>{{ item.icon }}</v-icon>
                          <span>{{ item.title }}</span>
                          <v-icon right>mdi-menu-down</v-icon>
                        </v-btn>
                        <v-btn
                          v-if="
                            item.customPermission ||
                            checkPermission(item.permission)
                          "
                          text
                          :disabled="item.disabled"
                          :color="$vuetify.theme.dark ? '' : 'grey darken-1'"
                          class="hidden-md-and-up uppercase"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>{{ item.icon }}</v-icon>
                          <v-icon right>mdi-menu-down</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <span v-for="(item, index) in item.list" :key="index">
                          <v-divider v-if="item.divider"></v-divider>
                          <span v-else>
                            <v-list-item
                              v-if="
                                item.customPermission ||
                                checkPermission(item.permission)
                              "
                              link
                              :disabled="item.disabled"
                              @click="item.function"
                            >
                              <v-list-item-icon v-if="item.icon">
                                <v-icon :disabled="item.disabled" dense>{{
                                  item.icon
                                }}</v-icon>
                              </v-list-item-icon>
                              <v-list-item-title>{{
                                item.title
                              }}</v-list-item-title>
                            </v-list-item>
                          </span>
                        </span>
                      </v-list>
                    </v-menu>
                  </div>
                  <div v-else-if="item.divider">
                    <v-divider
                      vertical
                      class="mx-1"
                      style="display: inline"
                    ></v-divider>
                  </div>
                  <div v-else>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-if="
                            item.customPermission ||
                            checkPermission(item.permission)
                          "
                          icon
                          :disabled="item.disabled"
                          class="uppercase"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>{{ item.icon }}</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ item.title }}</span>
                    </v-tooltip>
                  </div>
                </div>
              </slot>
            </v-toolbar>
            <slot name="tab-bar">
              <div v-if="!!tabs">
                <v-tabs
                  v-model="activeTab"
                  show-arrows
                  grow
                  class="rounded-b-lg"
                >
                  <v-tab
                    v-for="tab of filteredTabs"
                    :key="tab.id"
                    :to="tab.route"
                    :disabled="tab.disabled"
                    exact-path
                  >
                    {{ tab.name }}
                  </v-tab>
                  <v-tab-item
                    v-for="tab of filteredTabs"
                    :key="tab.id"
                    :value="tab.route"
                    :disabled="tab.disabled"
                  >
                  </v-tab-item>
                </v-tabs>
              </div>
            </slot>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "navigation-bar",
  props: {
    titel: {
      type: String,
      required: true,
    },
    showDiscardBtn: {
      type: Boolean,
      default: false,
    },
    discardBtnAction: {
      required: false,
    },
    showBackBtn: {
      type: Boolean,
      default: false,
    },
    backRoute: {
      type: String,
      default: "",
      required: false,
    },
    actions: {
      type: Array,
      required: false,
    },
    dense: {
      type: Boolean,
      default: false,
      required: false,
    },
    tabs: {
      type: Array,
      required: false,
    },
    // activeTab: {
    //   type: String,
    //   required: false,
    // },
    isPreview: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      snackbar: false,
      snackbarText: "",
      activeTab: window.location.href,
    };
  },
  computed: {
    filteredTabs() {
      try {
        if (!this.tabs) {
          return [];
        }
        return this.tabs.filter((tab) => {
          if (tab.permission === false) {
            return false;
          }
          return tab.permission ? this.checkPermission(tab.permission) : true;
        });
      } catch (error) {
        console.error("Error filtering tabs:", error);
        return [];
      }
    },
  },
  methods: {
    checkPermission(item) {
      if (!item) {
        return true;
      } else if (item === false) {
        return false;
      } else {
        return this.$store.getters["organization/checkPermissionByID"](item);
      }
    },
    backBtnClicked() {
      if (!this.backRoute == "") {
        this.$router.push({
          name: this.backRoute,
          params: {
            locale: this.$i18n.locale,
            organizationId: this.$route.params.organizationId,
          },
        });
      } else {
        // router.back()
        history.back();
      }
    },
    copyToCLipboard() {
      const route = "https://app.mira-one.com" + this.$router.currentRoute.path;
      var result = this.copyToClipboard(route);
      console.log("copied?", result);
    },
    // Copies a string to the clipboard. Must be called from within an event handler such as click.
    // May return false if it failed, but this is not always
    // possible. Browser support for Chrome 43+, Firefox 42+, Edge and IE 10+.
    // No Safari support, as of (Nov. 2015). Returns false.
    // IE: The clipboard feature may be disabled by an adminstrator. By default a prompt is
    // shown the first time the clipboard is used (per session).
    copyToClipboard(text) {
      if (window.clipboardData && window.clipboardData.setData) {
        // IE specific code path to prevent textarea being shown while dialog is visible.
        return window.clipboardData.setData("Text", text);
      } else if (
        document.queryCommandSupported &&
        document.queryCommandSupported("copy")
      ) {
        var textarea = document.createElement("textarea");
        textarea.textContent = text;
        textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in MS Edge.
        document.body.appendChild(textarea);
        textarea.select();
        try {
          return document.execCommand("copy"); // Security exception may be thrown by some browsers.
        } catch (ex) {
          console.warn("Fehler beim Kopieren in Zwischenablage.", ex);
          this.snackbarText = "Fehler beim Kopieren in Zwischenablage!";
          this.snackbar = true;
          return false;
        } finally {
          document.body.removeChild(textarea);
          this.snackbarText = "Link erfolgreich in Zwischenablage kopiert!";
          this.snackbar = true;
        }
      }
    },
  },
};
</script>

<style>
/* #navigation-bar {
        margin-top: -15px;
    } */
.uppercase {
  text-transform: uppercase;
  letter-spacing: 0.0892857143em;
}
</style>
